@import "variables";
@import "framework/framework";
// @import "../../node_modules/@fortawesome/fontawesome-pro/scss/fontawesome";
// @import "../../node_modules/@fortawesome/fontawesome-pro/scss/brands";
// @import "../../node_modules/@fortawesome/fontawesome-pro/scss/solid";

html {
	font-family: $primary-font;
	font-size: 16px;
	line-height: 1;
	width: 100%;
	overflow-x: hidden;

	@include desktop-sm-down {
		font-size: 15px;
	}

	@include tablet-down {
		font-size: 14px;
	}

	@include phone-down {
		font-size: 13px;
	}

	@include phone-sm-down {
		font-size: 12px;
	}
}

body {
	background-color: $color-cream;
	color: $color-off-black;
	width: 100%;
	overflow-x: hidden;
	display: flex;
	flex-direction: column;
	min-height: 100vh;
	position: relative;


	a {
		color: inherit;

		@include hover-focus {
			opacity: .6;
		}

		&.btn {
			transition: all .3s ease-in-out;
			padding: rems(9) rems(26) rems(6);
			border: 2px solid $color-off-black;
			background-color: transparent;

			@include hover-focus {
				border: 2px solid $color-red !important;
				color: $color-red;
			}
		}
	}

	// body
	&.active {
		background-color: $color-off-black;
		color: $color-cream;

		a.btn {
			border: 2px solid $color-cream;
		}
	}

	main {
		flex: 1;
	}
}

header {
	padding: rems(80) 0;
	position: relative;
	z-index: 1000;
	transition: all .3s ease-in-out;

	@include tablet-down {
		&.active {
			background-color: $color-black;

			&.interior {
				background-color: $color-cream;
			}
		}
	}

	.container {
		nav {
			text-transform: uppercase;
			display: flex;

			@include tablet-down {
				flex-direction: column;
				align-items: center;
			}

			.logo-wrapper {
				h1 {
					margin: 0;

					a {
						font-size: rems(32);
						color: $color-red !important;

						@include tablet-down {
							font-size: rems(48);
						}
					}
				}
			} 

			.mobile-wrapper {
				display: flex;
				flex: 1;
				// background-color: $color-cream;

				@include tablet-down {
					flex-direction: column;
					align-items: center;
					opacity: 0;
					pointer-events: none;
					position: absolute;
					left: 0;
					top: 100%;
					right: 0;
					transition: all .3s ease-in-out;

					&.active {
						opacity: 1;
						pointer-events: all;
						background-color: $color-black;

						li {
							a {
								color: $color-cream;
							}
						}
					}
				}

				.menu-wrapper {
					flex: 1;
					display: flex;
					align-items: center;

					ul#nav-menu {
						width: 100%;
						list-style: none;
						padding: 0 rems(40);
						margin: 0;
						display: flex;
						align-items: center;
						justify-content: space-around;

						@include tablet-down {
							flex-direction: column;
							align-items: center;
						}

						li {

							@include tablet-down {
								margin-bottom: rems(32);
							}

							a {
								font-size: rems(22);
							}
						}
					}
				}

				.featured-link {

					@include tablet-down {
						padding-bottom: rems(40);
					}

					a {
						font-size: rems(32);
						color: $color-red;

						@include hover-focus {
							opacity: .6;
						}
					}
				}
			}
		}
	}

	.mobile-menu-wrapper {
		position: absolute;
		top: rems(72);
		right: rems(20);

		.mobile-menu-icon {

			flex-direction: column;
			justify-content: center;
			align-items: center;
			pointer-events: all;
			cursor: pointer;
			position: relative;
			z-index: 30;
			display: none;

			// ICON SIZE
			width: 35px;
			height: 40px;

			@include tablet-down {
				display: flex;
			}

		}

		.line {
			width: 100%;
			height: 2px;
			background-color: $color-off-black;
			margin: 4.5px;
			transition: transform 0.3s ease;
			transform-origin: center;

			&.internal {
				background-color: $color-cream;
			}


			&.active {
				transform: rotate(45deg) !important;
				position: absolute;
				top: 30%;
				background-color: $color-cream;

				&.active:nth-child(2) {
					display: none;
				}

				&.active:nth-child(3) {
					transform: rotate(-45deg) !important;
				}
			}
		}
	}
}

main {

	section {
		margin: 0 0 rems(100);
		display: none;
	}

	section#home-hero {
		.container {
			.content {
				display: flex;
				text-align: center;

				@include tablet-down {
					flex-direction: column;

				}

				.cover-wrapper {
					flex: 1;
					padding: rems(40);

					img {
						box-shadow: 0px 3px 4px $color-bababa;
					}
				}

				.release-wrapper {
					flex: 1;
					padding: rems(40);
					display: flex;
					flex-direction: column;
					align-items: center;
					justify-content: center;

					p.artist {
						font-size: rems(44);
					}

					h2 {
						font-size: rems(64);
						letter-spacing: .03em;
						margin: 0 0 rems(20);
					}

					.btn {}
				}
			}
		}
	}

	section#music {
		.container {
			.music-wrapper {
				display: flex;
				flex-wrap: wrap;
				gap: 40px;

				.item {
					width: calc(50% - 40px);
					display: flex;
					flex-direction: column;
					justify-content: center;
					align-items: center;
					margin-bottom: rems(40);

					@include phone-down {
						width: 100%;
					}

					img {
						width: 100%;
						aspect-ratio: 1/1;
						object-fit: cover;
						max-width: rems(350);
						margin-bottom: rems(22);
					}

					p.title {
						font-size: rems(20);
						text-align: center;
						text-transform: uppercase;
						flex: 1;
						width: 100%;
						margin-bottom: rems(12);
					}

					a.btn {
						font-size: rems(27);
					}
				}
			}
		}
	}

	section#video {
		.container {
			.video-wrapper {
				display: flex;
				flex-wrap: wrap;
				gap: 5rem;

				@include tablet-down {
					gap: rems(20);
				}

				.item {
					width: calc(50% - 2.5rem);

					@include tablet-down {
						width: 100%;
					}

					.embed-container {
						margin-bottom: rems(16);

						.iframe {}
					}

					p.title {
						font-size: rems(20);
						text-align: center;
						text-transform: uppercase;
					}
				}
			}
		}
	}

	section#tour {
		.container {
			h2 {
				font-size: rems(120);
				color: $color-red;
				text-align: center;
				text-transform: uppercase;
				letter-spacing: .03em;
				margin: 0 0 rems(24);
			}

			#tour-dates {
				text-transform: uppercase;

				.event-group {
					display: flex;
					gap: rems(16);
					margin: rems(24) 0;
					font-size: rems(22);

					@include tablet-down {
						text-align: center;
						flex-direction: column;
						align-items: center;
						margin-bottom: rems(60);
						font-size: rems(30);
					}

					.event-date {
						flex: 1;
					}

					.event-venue {
						flex: 1.5;
					}

					.event-location {
						flex: 1.5;
					}

					.event-links {
						align-items: flex-start;
						flex: 1;
						display: flex;
						justify-content: flex-end;
						gap: rems(10);

						a.btn {

							text-align: center;
							font-size: rems(16);
							padding: rems(8) rems(16) rems(5);
						}
					}
				}
			}
		}
	}

	section#newsletter {
		.container {
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;

			h2 {
				font-size: rems(50);
				text-transform: uppercase;
				text-align: center;
			}

			form {
				gap: rems(20);
				font-size: rems(27);
				text-transform: uppercase;
				display: flex;
				max-width: rems(500);
				width: 100%;

				.form-group {
					width: 100%;
				}

				.display-none {
					display: none;
				}

				input {
					background-color: transparent;
					border: 1px solid $color-cream;
					color: $color-cream;
					text-transform: uppercase;
					width: 100%;
					padding: rems(10) rems(20) rems(5);

					&::placeholder {
						color: $color-cream;
						opacity: .4;
					}
				}


				select {
					background-color: transparent;
					border: 1px solid $color-cream;
					color: $color-cream;
					text-transform: uppercase;

					&::placeholder {
						color: $color-cream;
						opacity: .4;
					}
				}


				.btn {
					font-size: rems(27);
					text-transform: uppercase;
					white-space: nowrap;
					border: 1px solid $color-cream;
					background-color: $color-off-black;
					padding: rems(10) rems(20) rems(5);
					color: $color-cream;
				}
			}
		}
	}

	section#promo {
		background-image: url("../dist/img/bg/texture.png");
		background-position: center;
		background-size: cover;
		background-repeat: no-repeat;

		.container {
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;

			img.promo-img-title {
				margin-bottom: rems(20);
			}

			p.release {
				font-size: rems(50);
				text-transform: uppercase;
				margin-bottom: rems(60);
			}

			.featured-content {
				width: 100%;
				display: flex;
				justify-content: space-between;
				gap: rems(40);

				@include tablet-down {
					flex-direction: column;
					align-items: center;
					justify-content: center;
				}

				.music-content {
					// flex: 4;


					img {
						height: rems(350);
						aspect-ratio: 1/1;
						margin-bottom: rems(20);
					}

					.btn-wrapper {
						display: flex;
						justify-content: center;

						a.btn {
							font-size: rems(23);
							padding: rems(10) rems(26) rems(6);
						}
					}
				}

				.video-content {
					// flex: 6;
					// display: flex;
					// flex-direction: column;
					// align-items: flex-end;

					.embed-wrapper {
						height: rems(350);
						aspect-ratio: 16/9;
						margin-bottom: rems(20);

						@include tablet-down {
							height: rems(250);
						}

						.embed-container {}
					}

					.btn-wrapper {
						width: 100%;
						display: flex;
						justify-content: center;

						a.btn {
							font-size: rems(23);
							padding: rems(10) rems(26) rems(6);
						}
					}
				}
			}
		}
	}

}

footer {

	.container {
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;

		ul.social {
			display: flex;
			align-items: center;
			list-style: none;
			gap: rems(30);
			padding: 0;
			margin: 0 0 rems(30);

			li {
				a {
					transition: all .3s ease-in-out;

					@include hover-focus {
						opacity: 0.5;
					}

					i {
						font-size: rems(30);
					}

					img {
						width: 100%;
						height: 100%;
						max-width: rems(30);
						max-height: rems(30);
					}
				}
			}
		}

		.copyright {
			font-family: $footer-font;
			font-size: rems(13);
			text-align: center;
			width: 100%;
			max-width: rems(800);
			margin: 0 0 rems(40);
		}
	}
}

// .fab, .fa-brands {
// 	font-family: "Font Awesome 6 Brands" !important;
// }