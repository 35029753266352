$fa-font-path: "../font";
$html-font-size: 16;
$color-white: #ECECEC;
$color-black: #000000;
$color-charcoal: #323232;
$color-red: #941C18;
$color-off-black: #101010;
$color-cream: #f0eae1;
$color-bababa: #bababa;


@font-face {
  font-family: 'Robuck';
  src: url('../font/Robuck-Regular.woff2') format('woff2'),
      url('../font/Robuck-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

$primary-font: 'Robuck',Helvetica Neue, Helvetica, Arial, sans-serif;
$footer-font: Helvetica Neue, Helvetica, Arial, sans-serif;
 